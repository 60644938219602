module PointOfSale exposing (Flags, Model, main)

import Browser exposing (Document)
import Browser.Navigation as Nav
import Cmd.Extra as Cmd
import Css
import Css.Global
import DX.Theme as Theme
import DX.Utilities as Tw exposing (globalStyles)
import Html.Styled as Html exposing (Html, div, li, span, text, ul)
import Html.Styled.Attributes exposing (css)
import Json.Decode as Decode
import List.NonEmpty
import Maybe.Extra as Maybe
import PointOfSale.Api as Api
import PointOfSale.Assets exposing (Assets)
import PointOfSale.Cart as Cart
import PointOfSale.Commons as Commons exposing (Commons(..), InUseCommons)
import PointOfSale.Config as Config
import PointOfSale.Msg exposing (Msg(..))
import PointOfSale.Pages.Concessions as Concessions
import PointOfSale.Pages.LoggedOut as LoggedOut
import PointOfSale.Pages.Options as Options
import PointOfSale.Pages.Orders as Orders
import PointOfSale.Pages.Products as Products
import PointOfSale.Pages.Setup as Setup
import PointOfSale.Pages.Tickets as Tickets
import PointOfSale.QuickAccessBar as QuickAccessBar
import PointOfSale.Router as Router exposing (Page(..), PagePart(..))
import PointOfSale.Routes as Routes
import PointOfSale.SvgImages as SvgImages
import PointOfSale.Widgets.Checkout as Checkout
import Time
import Ui.Logo
import Url exposing (Url)
import Utils.Fiveten.DateFormatCustom as DateFormat
import Utils.Html as Html



{--- MODEL ---}


type alias Model =
    { page : Page
    , key : Nav.Key
    , commons : Commons
    , checkoutModel : Checkout.Model
    }


type alias Flags =
    { now : Int
    , readerId : String
    , releaseVersion : String
    , settings : Decode.Value

    -- NOTE: we could/should make this a `Decode.value` and validate it with a decoder but since for now it's just a bunch of strings, let's take the easy road and let elm take care of it automatically for now
    , assets : Assets
    }


init : Flags -> Url -> Nav.Key -> ( Model, Cmd Msg )
init { now, readerId, settings, assets, releaseVersion } url key =
    let
        maybePosSettings : Maybe Api.PosSettings
        maybePosSettings =
            settings
                |> Decode.decodeValue Api.posSettingsDecoder
                |> Result.toMaybe

        ( commons, page, cmd ) =
            Router.init releaseVersion assets (Time.millisToPosix now) (Api.readerId readerId) maybePosSettings url
    in
    ( { page = page
      , key = key
      , commons = commons
      , checkoutModel = Checkout.init
      }
    , cmd
    )



{--- UPDATE ---}


getCheckoutConfig : Checkout.Config Msg
getCheckoutConfig =
    { msg = CheckoutMsg
    , setCart = CommonsMsg << Commons.SetCart
    , getDiscounts = CommonsMsg <| Commons.StoreMsg Commons.GetDiscounts
    , removeConcession =
        \c ->
            Cart.removeFromCart c
                |> Commons.SetCart
                |> CommonsMsg
    , removeConcessionCombo =
        \c ->
            Cart.removeComboFromCart c
                |> Commons.SetCart
                |> CommonsMsg
    , changeConcessionCount =
        \count concession ->
            Cart.addToCart count concession
                |> Commons.SetCart
                |> CommonsMsg
    , changeConcessionComboCount =
        \count concession ->
            Cart.addComboToCart count concession
                |> Commons.SetCart
                |> CommonsMsg
    , navigateTo = NavigateTo
    , concessionsBarcodeScanned = ConcessionsBarcodeScanned
    }


mapPage : (InUseCommons -> ( PagePart, Cmd Msg )) -> Commons -> Page -> ( Page, Cmd Msg )
mapPage fn commons page =
    case commons of
        Misconfigured _ ->
            ( page, Cmd.none )

        SettingsMissing _ ->
            ( page, Cmd.none )

        Bootstraping _ ->
            ( page, Cmd.none )

        InUse inUseCommons ->
            case page of
                NotFound _ ->
                    ( page, Cmd.none )

                Found ( route, _ ) ->
                    fn inUseCommons
                        |> (\( newPagePart, cmd ) ->
                                ( Found ( route, newPagePart ), cmd )
                           )


update : Msg -> Model -> ( Model, Cmd Msg )
update msg ({ commons, key } as model) =
    case ( msg, Router.getPagePart model.page ) of
        ( UrlRequested urlRequest, _ ) ->
            case urlRequest of
                Browser.Internal url ->
                    let
                        ( newCommons, page, cmd ) =
                            Router.transitionTo commons model.page url
                    in
                    ( { model | commons = newCommons, page = page }
                    , Cmd.batch
                        [ Nav.pushUrl model.key (Url.toString url)
                        , cmd
                        ]
                    )

                Browser.External href ->
                    ( model, Nav.load href )

        ( UrlChanged url, _ ) ->
            let
                ( newCommons, page, cmd ) =
                    Router.transitionTo commons model.page url
            in
            ( { model | commons = newCommons, page = page }
            , cmd
            )

        ( NavigateTo route, _ ) ->
            ( model, Nav.pushUrl model.key (Routes.routeToUrl route) )

        -- Commons update
        ( CommonsMsg commonsMsg, page ) ->
            let
                ( newCommons, commonsCmd ) =
                    Commons.update commonsMsg
                        { canRedirect =
                            case page of
                                LoggedOut _ ->
                                    False

                                _ ->
                                    True
                        , navKey = model.key
                        , msg = CommonsMsg
                        , commonsReady = CommonsReady
                        }
                        commons
                        |> (\( tempCommons, cmd ) ->
                                if Commons.isReady tempCommons && Router.isLoadingPage model.page then
                                    ( tempCommons
                                    , Cmd.batch
                                        [ cmd, Nav.pushUrl key (Routes.routeToUrl Routes.Tickets) ]
                                    )

                                else
                                    ( tempCommons, cmd )
                           )
            in
            ( { model | commons = newCommons }, commonsCmd )

        -- Pages updates
        ( TicketsMsg ticketsMsg, Tickets ticketsModel ) ->
            mapPage
                (\inUseCommons ->
                    Tickets.update PointOfSale.Msg.ticketsConfig
                        ticketsMsg
                        inUseCommons
                        ticketsModel
                        |> Tuple.mapFirst Tickets
                )
                commons
                model.page
                |> Tuple.mapFirst (\newPage -> { model | page = newPage })

        ( OrdersMsg ordersMsg, Orders ordersModel ) ->
            mapPage
                (\inUseCommons ->
                    Orders.update ordersMsg model.key inUseCommons ordersModel
                        |> Tuple.mapBoth Orders (Cmd.map OrdersMsg)
                )
                commons
                model.page
                |> Tuple.mapFirst (\newPage -> { model | page = newPage })

        ( OptionsMsg optionsMsg, Options optionsModel ) ->
            mapPage
                (\inUseCommons ->
                    Options.update optionsMsg inUseCommons optionsModel
                        |> Tuple.mapBoth Options (Cmd.map OptionsMsg)
                )
                commons
                model.page
                |> Tuple.mapFirst (\newPage -> { model | page = newPage })

        ( LoggedOutMsg loggedOutMsg, LoggedOut loggedOutModel ) ->
            case commons of
                Misconfigured _ ->
                    ( model, Cmd.none )

                SettingsMissing _ ->
                    ( model, Cmd.none )

                Bootstraping bootstrapingCommons ->
                    let
                        ( newLoggedoutModel, cmd ) =
                            LoggedOut.update loggedOutMsg bootstrapingCommons loggedOutModel
                    in
                    ( { model
                        | page = Router.mapPagePart model.page (always <| LoggedOut newLoggedoutModel)
                      }
                    , Cmd.map LoggedOutMsg cmd
                    )

                InUse inUseCommons ->
                    let
                        ( newLoggedoutModel, cmd ) =
                            LoggedOut.update loggedOutMsg (Commons.bootstrapingFromInUse inUseCommons) loggedOutModel
                    in
                    ( { model
                        | page = Router.mapPagePart model.page (always <| LoggedOut newLoggedoutModel)
                      }
                    , Cmd.map LoggedOutMsg cmd
                    )

        ( SetupMsg setupMsg, Setup ) ->
            let
                ( newCommons, cmd ) =
                    case commons of
                        Misconfigured _ ->
                            ( commons, Cmd.none )

                        SettingsMissing _ ->
                            ( commons, Cmd.none )

                        Bootstraping bootstrapingCommons ->
                            Setup.update setupMsg bootstrapingCommons
                                |> Tuple.mapFirst Bootstraping

                        InUse inUseCommons ->
                            Setup.update setupMsg (Commons.bootstrapingFromInUse inUseCommons)
                                |> Tuple.mapFirst Bootstraping
            in
            ( { model
                | commons = newCommons
                , page = Router.mapPagePart model.page (always Setup)
              }
            , Cmd.map SetupMsg cmd
            )

        ( ConcessionsMsg cMsg, Concessions cModel ) ->
            mapPage
                (\inUseCommons ->
                    Concessions.update PointOfSale.Msg.concessionsConfig
                        inUseCommons
                        cMsg
                        cModel
                        |> Tuple.mapFirst Concessions
                )
                commons
                model.page
                |> Tuple.mapFirst (\newPage -> { model | page = newPage })

        ( ProductsMsg pMsg, Products pModel ) ->
            mapPage
                (\_ ->
                    Products.update PointOfSale.Msg.productsConfig
                        pMsg
                        pModel
                        |> Tuple.mapFirst Products
                )
                commons
                model.page
                |> Tuple.mapFirst (\newPage -> { model | page = newPage })

        ( ConcessionsBarcodeScanned, Concessions cModel ) ->
            mapPage
                (\_ ->
                    Concessions.inpuptCameFromBarcodeScanner cModel
                        |> Concessions
                        |> Cmd.pure
                )
                commons
                model.page
                |> Tuple.mapFirst (\newPage -> { model | page = newPage })

        ( CheckoutMsg cMsg, _ ) ->
            case commons of
                Misconfigured _ ->
                    ( model, Cmd.none )

                SettingsMissing _ ->
                    ( model, Cmd.none )

                Bootstraping _ ->
                    ( model, Cmd.none )

                InUse inUseCommons ->
                    let
                        ( newCheckoutModel, cmd ) =
                            Checkout.update
                                getCheckoutConfig
                                cMsg
                                inUseCommons
                                model.checkoutModel
                    in
                    ( { model | checkoutModel = newCheckoutModel }, cmd )

        ( ReloadPage, _ ) ->
            ( model, Nav.reload )

        ( CommonsReady, _ ) ->
            case commons of
                Misconfigured _ ->
                    ( model, Cmd.none )

                SettingsMissing _ ->
                    ( model, Cmd.none )

                Bootstraping _ ->
                    ( model, Cmd.none )

                InUse _ ->
                    ( { model | checkoutModel = Checkout.commonsReady model.checkoutModel }, Cmd.none )

        ( _, Tickets _ ) ->
            ( model, Cmd.none )

        ( _, Concessions _ ) ->
            ( model, Cmd.none )

        ( _, Products _ ) ->
            ( model, Cmd.none )

        ( _, Orders _ ) ->
            ( model, Cmd.none )

        ( _, Options _ ) ->
            ( model, Cmd.none )

        ( _, LoggedOut _ ) ->
            ( model, Cmd.none )

        ( _, Setup ) ->
            ( model, Cmd.none )

        ( _, LoadingPage ) ->
            ( model, Cmd.none )

        ( _, LoggedIn ) ->
            ( model, Cmd.none )


subscriptions : Model -> Sub Msg
subscriptions { page, commons, checkoutModel } =
    -- update the clock every minute
    Sub.batch
        [ Commons.subscriptions commons |> Sub.map CommonsMsg
        , case commons of
            Misconfigured _ ->
                Sub.none

            SettingsMissing _ ->
                Sub.none

            Bootstraping _ ->
                Sub.none

            InUse inUseCommons ->
                Checkout.subscriptions inUseCommons checkoutModel
                    |> Sub.map CheckoutMsg
        , case Router.getPagePart page of
            Setup ->
                (case commons of
                    Misconfigured _ ->
                        Sub.none

                    SettingsMissing _ ->
                        Sub.none

                    Bootstraping bootstrapingCommons ->
                        Setup.subscriptions bootstrapingCommons

                    InUse inUseCommons ->
                        Setup.subscriptions (Commons.bootstrapingFromInUse inUseCommons)
                )
                    |> Sub.map SetupMsg

            Orders ordersModel ->
                Orders.subscriptions ordersModel
                    |> Sub.map OrdersMsg

            _ ->
                Sub.none
        ]



{--- VIEW ---}


layout : Model -> Html Msg
layout ({ commons } as model) =
    let
        sidebar : Html Msg
        sidebar =
            Html.div [ css [ Tw.h_full, Tw.overflow_hidden ] ]
                [ QuickAccessBar.view ReloadPage commons model.page
                ]

        errorView : Commons.BasicCommons -> String -> List (Html Msg) -> Html Msg
        errorView { timezone, now } message content =
            div [ css [ Tw.p_32px, Tw.flex, Tw.flex_col, Tw.justify_between, Tw.items_center, Tw.h_screen, Tw.w_full, Tw.space_y_16px ] ] <|
                [ case timezone of
                    Just timezone_ ->
                        div [ css [ Tw.self_end, Tw.text_20px, Tw.flex, Tw.flex_row, Tw.space_x_8px ] ]
                            [ span [] [ now |> DateFormat.formatDateMonth timezone_ |> text ]
                            , span [] [ now |> DateFormat.formatTime timezone_ |> text ]
                            ]

                    Nothing ->
                        Html.nothing
                , Ui.Logo.dxGradient [ Tw.h_32px ]
                , div [ css [ Tw.flex, Tw.flex_grow, Tw.justify_center, Tw.items_center, Tw.flex_col, Tw.space_y_12px ] ]
                    [ SvgImages.configurationError
                    , span [ css [ Tw.text_24px ] ]
                        [ text message ]
                    ]
                ]
                    ++ content

        withCheckoutView : Commons.InUseCommons -> Html Msg -> Html Msg
        withCheckoutView inUseCommons content =
            div
                [ [ Tw.grid
                  , Tw.p_0
                  , Tw.relative
                  , Tw.h_full
                  , Css.property "grid-template-columns" "1fr minmax(300px, 400px)"
                  , Tw.overflow_hidden
                  ]
                    |> css
                ]
                [ content
                , Checkout.view inUseCommons model.checkoutModel
                    |> Html.map CheckoutMsg
                ]

        pageContent : Html Msg
        pageContent =
            case ( commons, model.page ) of
                ( _, NotFound notFoundUrl ) ->
                    errorView (Commons.basicFromAny commons)
                        "The page you are looking for cannot be found."
                        [ div [ css [ Tw.space_x_8px ] ]
                            [ span [] [ text "trying to reach: " ]
                            , span [ css [ Tw.font_mono ] ] [ text <| Url.toString notFoundUrl ]
                            ]
                        ]

                ( Bootstraping bootstrapingCommons, Found ( _, Setup ) ) ->
                    Setup.view bootstrapingCommons
                        |> Html.map SetupMsg

                ( InUse inUseCommons, Found ( _, Setup ) ) ->
                    Setup.view (Commons.bootstrapingFromInUse inUseCommons)
                        |> Html.map SetupMsg

                ( SettingsMissing basicCommons, _ ) ->
                    errorView basicCommons
                        "This POS has been miss-initialised. Try restarting it."
                        [ ul [ css [ Tw.flex_col, Tw.flex, Tw.items_start, Tw.space_y_4px, Tw.text_left ] ]
                            (basicCommons.baseUrl
                                |> Router.debuggingQueryInfo
                                |> (\{ deviceId, hash, paymentMethods } ->
                                        [ ( "deviceId", Maybe.unwrap "Missing" Api.idToString deviceId )
                                        , ( "hash", Maybe.unwrap "Missing" Api.idToString hash )
                                        , ( "paymentMethods"
                                          , Maybe.unwrap "Missing"
                                                (List.NonEmpty.toList
                                                    >> List.map Api.paymentMethodToString
                                                    >> String.join ", "
                                                )
                                                paymentMethods
                                          )
                                        ]
                                   )
                                |> List.map
                                    (\( name, endpoint ) ->
                                        li [ css [ Tw.space_x_8px ] ]
                                            [ span [] [ text name ]
                                            , span [ css [ Tw.font_mono ] ] [ text endpoint ]
                                            ]
                                    )
                            )
                        ]

                ( Misconfigured ({ baseUrl } as basicCommons), _ ) ->
                    errorView basicCommons
                        "Something is wrong in the POS configuration. Please, contact the support team."
                        [ Html.button
                            [ css
                                [ Css.focus
                                    [ Css.Global.descendants
                                        [ Css.Global.typeSelector "div"
                                            [ Tw.visible
                                            ]
                                        ]
                                    ]
                                ]
                            ]
                            [ div [ css [ Tw.invisible ] ]
                                [ ul [ css [ Tw.flex_col, Tw.flex, Tw.items_start, Tw.space_y_4px, Tw.text_left ] ]
                                    ([ ( "baseUrl", baseUrl |> Url.toString )
                                     , ( "onsite api", Config.baseSalePlatformUrl )
                                     , ( "id api", Config.idDomain )
                                     ]
                                        |> List.map
                                            (\( name, endpoint ) ->
                                                li [ css [ Tw.space_x_8px ] ]
                                                    [ span [] [ text name ]
                                                    , span [ css [ Tw.font_mono ] ] [ text endpoint ]
                                                    ]
                                            )
                                    )
                                ]
                            ]
                        ]

                ( Bootstraping bootstrapingCommons, Found ( _, LoggedOut loggedOutModel ) ) ->
                    LoggedOut.view bootstrapingCommons loggedOutModel
                        |> Html.map LoggedOutMsg

                ( Bootstraping _, _ ) ->
                    Commons.bootstrapingFullPageView commons ReloadPage <| Routes.routeToUrl Routes.Setup

                ( InUse inUseCommons, Found ( _, Tickets ticketsModel ) ) ->
                    Tickets.view inUseCommons ticketsModel
                        |> Html.map TicketsMsg
                        |> withCheckoutView inUseCommons

                ( InUse inUseCommons, Found ( _, Options optionsModel ) ) ->
                    Options.view inUseCommons optionsModel
                        |> Html.map OptionsMsg

                ( InUse inUseCommons, Found ( _, LoggedOut loggedOutModel ) ) ->
                    LoggedOut.view (Commons.bootstrapingFromInUse inUseCommons) loggedOutModel
                        |> Html.map LoggedOutMsg

                ( InUse inUseCommons, Found ( _, Concessions cModel ) ) ->
                    Concessions.view inUseCommons cModel
                        |> Html.map ConcessionsMsg
                        |> withCheckoutView inUseCommons

                ( InUse inUseCommons, Found ( _, Products pModel ) ) ->
                    Products.view inUseCommons pModel
                        |> Html.map ProductsMsg
                        |> withCheckoutView inUseCommons

                ( InUse inUseCommons, Found ( route, Orders ordersModel ) ) ->
                    Orders.view inUseCommons route ordersModel |> Html.map OrdersMsg

                ( InUse _, Found ( _, LoadingPage ) ) ->
                    div [] [ text "We're piecing together what you need to work." ]

                ( InUse _, Found ( _, LoggedIn ) ) ->
                    div [] [ text "you are logged in and you'll be redirected soon." ]
    in
    div
        [ css
            [ Tw.grid
            , Tw.h_screen
            , Tw.w_screen
            , Tw.p_0
            , Tw.m_0
            , Tw.overflow_hidden
            , Tw.font_inter
            , Tw.bg_color Theme.bg_light_1
            , Css.property "grid-template-columns" "120px 1fr"
            ]
        ]
        [ sidebar
        , pageContent
        ]


view : Model -> Document Msg
view model =
    { title = "Point Of Sale"
    , body =
        [ Html.toUnstyled <| Css.Global.global globalStyles
        , Html.toUnstyled <|
            layout model
        ]
    }



{--- PROGRAM ---}


main : Program Flags Model Msg
main =
    Browser.application
        { view = view
        , init = init
        , update = update
        , subscriptions = subscriptions
        , onUrlChange = UrlChanged
        , onUrlRequest = UrlRequested
        }
